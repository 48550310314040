<template lang="">
    <div>
        <DeclarationList />
    </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
    mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Emlak Beyanı", route: "/declaration" },
    ]);
  },
    components: {
    DeclarationList: () => import('@/components/declaration/DeclarationList'),

  },

}
</script>
